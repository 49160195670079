<template>
  <base-popup
    title="Экспорт таблицы в Excel"
    :persistent="false"
    onlyClose
    v-model="dialog"
  >
    <v-container>
      <div class="d-flex flex-column">
        <v-btn-toggle v-model="optionsExport.type" tile color="primary" group>
          <v-btn>Полный эскпорт</v-btn>
          <v-btn v-if="false">Выбранные строки</v-btn>
        </v-btn-toggle>
        <div class="my-5">
          <text-grid
            :width="400"
            :items="[
              ['Примерное время эспорта:', info.time + ' сек.'],
              ['Экспортируется строк:', info.col],
            ]"
          ></text-grid>
        </div>
        <v-btn :loading="loading" @click="handleExport" dark color="primary"
          >Скачать Excel файл</v-btn
        >
      </div>
    </v-container>
  </base-popup>
</template>
<script>
/*
  1) Сделать заголовок
  2) Проработать исключения
  3) Настройки
    - Выбранные, видимые или все
    - отображать группы
  4) Вопрос по иерархиям ?
  5) Сделать интерфейс
*/

import * as XLSX from "xlsx";
import { findParent } from "@/utils/DomVueHelper";
import BasePopup from "@/layouts/BasePopup.vue";

import overlayable from "vuetify/lib/mixins/overlayable";

export default {
  name: "popup-export",
  components: {
    textGrid: () => import("@/components/base/textGrid"),
    BasePopup,
  },
  mixins: [overlayable],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Boolean,
      default: false,
    },
    setOpenGroup: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      optionsExport: {
        type: 0,
      },
      info: {
        col: 0,
        time: 5,
      },
      loading: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    "optionsExport.type": {
      immediate: true,
      handler() {
        this.updateInfo();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    updateInfo() {
      if (this.optionsExport.type === 0) {
        this.info.col = this.items.length;
      } else if (this.optionsExport.type === 1) {
        this.info.col = this.selectedItems.length;
      }
      this.info.time = parseInt(
        Math.round(Math.max(5, (this.info.col / 100) * 5))
      );
    },
    isBlank(obj) {
      let isPush = true;
      for (const property in obj) {
        if (obj[property]) isPush = false;
      }
      return isPush;
    },
    async startExport() {
      if (this.optionsExport.type === 0) {
        this.setOpenGroup("show");
        await new Promise((resolve) => {
          setTimeout(resolve, 1);
        });
      }

      const rootComponent = findParent(
        this,
        (e) => {
          return e?.dataSource;
        },
        10
      );

      const regExp = /\*|\\|\/|\?|\*|\[|\]|\$/g; // \ / ? * [ ]
      let title =
        rootComponent?.title ||
        window.document
          .querySelector(".v-toolbar__title")
          ?.firstChild?.textContent?.trim() ||
        "таблица";
      title = title.replace(regExp, "");

      const headers = [];
      const row = [];

      const domTable = rootComponent.$el.querySelector("table");
      const domThs = domTable.querySelectorAll(
        "thead.v-data-table-header tr th"
      );
      const domTrs = domTable.querySelectorAll(
        "tbody tr:not(.v-row-group__header):not(.base-table-full__filter)"
      );
      for (let i = 0; i < domThs.length; i++) {
        headers.push(domThs[i].querySelector("span")?.innerText || null);
      }

      // row
      for (let i = 0; i < domTrs.length; i++) {
        if (
          domTrs[i].classList.contains("v-data-table__expanded__content") &&
          !rootComponent.$el.querySelector(".base-tree-table")
        )
          continue;

        if (
          domTrs[i].getAttribute("class") ===
          "v-data-table__expanded v-data-table__expanded__content"
        )
          continue;

        const tds = domTrs[i].querySelectorAll(
          "td:not(.base-tree-table__children)"
        );
        const tdsArray = {};
        for (let j = 0; j < tds.length; j++) {
          if (headers[j]) {
            tdsArray[j] = tds[j].innerText;
          }
        }

        if (!this.isBlank(tdsArray)) row.push(tdsArray);
      }

      const worksheet = XLSX.utils.json_to_sheet(row);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(worksheet, [headers.filter(Boolean)], {
        origin: "A1",
      });

      const wscols = [];
      headers.filter(Boolean).forEach((arr) => {
        wscols.push({ wch: arr.length + 10 });
      });
      worksheet["!cols"] = wscols;

      XLSX.utils.book_append_sheet(workbook, worksheet, title.substr(0, 31));
      XLSX.writeFile(workbook, title + " Table_" + row.length + ".xlsx", {
        compression: true,
      });
    },
    async handleExport() {
      this.loading = true;

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      await this.startExport();
      this.setOpenGroup("showFirst");
      this.loading = false;
    },
  },
};
</script>
